import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getDisease } from '../../apis';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import InfoDialog from '../../components/InfoDialog';

var severity = {
  2: 'ควรพบแพทย์โดยด่วน',
  1: 'เฝ้าระวังหรือปรึกษาแพทย์เพิ่ม',
  0: 'อาการไม่รุนแรง',
}

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    '& > div + div': {
      marginTop: 10
    }
  },
  'severity-2': {
    color: '#FF0000',
  },
  'severity-1': {
    color: '#248CCB',
  },
  'severity-0': {
    color: '#4CAD00',
  }
}));

export default ({ items }) => {
  const classes = useStyles();
  const [dialog, setDialog] = React.useState();

  const openDialog = async (item) => {
    if (item) {
      const res = await getDisease(item.id);
      setDialog(res);
    }
  }

  const _items = items || [];

  return (
    <>
      <InfoDialog
        open={!!dialog}
        okText="ปิด"
        onConfirm={() => setDialog()}
        onClose={() => setDialog()}
        title=""
        content={dialog && <div dangerouslySetInnerHTML={{ __html: dialog.detail }} />}
      />
      <List className={classes.list}>
        {
          _items.filter(val => val.percentage >= 1).map((item, index) => {
            return (
              <Paper key={index} elevation={1}>
                <ListItem>
                  <ListItemText
                    primary={`${Math.floor(item.percentage)}% ${item.symptom || item.name}`}
                    secondary={
                      <span className={classes[`severity-${item.urgent_type}`]}>{severity[item.urgent_type]}</span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <a onClick={(e) => {
                      e.preventDefault();
                      openDialog(item);
                    }}>อ่านเพิ่ม</a>
                  </ListItemSecondaryAction>
                </ListItem>
              </Paper>
            );
          })
        }
      </List>
      <small>
      ** ข้อมูลอ้างอิงการประเมินโรคอ่านได้
        <a
          target="_blank"
          href="https://moronline.freshdesk.com/support/solutions/articles/72000598100-a-i-health-assessment-recommendation-citation"
        >
          {` ที่นี่ `}
        </a>
        **
      </small>
    </>
  )
}
