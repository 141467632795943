import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default ({ open, onClose, onConfirm, okText, content, title = '', disableBackdropClick = false }) => {
  return (
    <Dialog
      fullWidth
      disableBackdropClick={disableBackdropClick}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      scroll="paper"
    >
      <DialogTitle>{title || ''}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content || ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="secondary">
          {okText || 'ตกลง'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}