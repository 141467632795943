import React from 'react';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { getUrlParams } from '../../helpers/location';
import { postSummary } from '../../apis/ai';
import { rateEvaluation, closeEvaluation } from '../../apis';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../../components/Layout';
import CrossPlatformLink from '../../components/CrossPlatformLink';
import RatingDialog from '../../components/RatingDialog';
import DiseaseRisksList from '../../components/DiseaseRisksList';
import LoaderView from '../../components/LoaderView';

const sortByPercent = (a, b) => {
  return b.percent - a.percent;
}

export default ({ location }) => {
  const { source } = useSelector(state => state.session);
  const [rateDialog, setRateDialog] = React.useState(false);
  const [summary, setSummary] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const params = getUrlParams(location.search);
  const { assessmentId } = params;
  const { partnerId } = useSelector(state => state.partners);

  // from EmployeeHealth Flow(partner) ?
  const { verify } = useSelector(state => state.partners);

  React.useEffect(() => {
    if (assessmentId) {
      fetchResult(assessmentId);
    }
  }, [assessmentId]);

  const fetchResult = async (assessmentId) => {
    setLoading(true);
    try {
      const res = await postSummary(assessmentId);
      if (res.length) {

        const summary = res.map(val => ({
          id: val.id,
          symptom: val.name,
          percentage: val.percentage,
          urgent_type: val.urgent_type,
        })).sort(sortByPercent);

        setSummary(summary);

        closeEvaluation(assessmentId, {
          disease_risks: summary
        });

        setTimeout(() => { // Make sure rating is available only when response is valid.
          setRateDialog(true);
        }, 10000);
      }
    } catch (e) {
    }
    setLoading(false);
  }

  const handleRating = async (score, text) => {
    if (score) {
      setRateDialog(false);
      await rateEvaluation(assessmentId, { score, text });
    }
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ผลการประเมิน - MorOnline</title>
      </Helmet>
      <RatingDialog open={!!rateDialog} onSubmit={handleRating} />
      <Box p={2} maxWidth={800} ml="auto" mr="auto">
        <h2>ผลการประเมินอาการของคุณ</h2>
        <p>จากการประเมินสุขภาพของคุณด้วย A.I. คุณมีอาการเสี่ยงดังต่อไปนี้ <br /><small>* ข้อมูลดังกล่าวเป็นเพียงการวินิจฉัยเบื้องต้นเท่านั้น ควรปรึกษาแพทย์เพื่อตรวจโดยละเอียด</small></p>
        <LoaderView
          loading={loading && summary.length === 0}
          empty={!loading && summary.length === 0}
        >
          <>
            <DiseaseRisksList items={summary} />
            {
              source === 'station' ? (
                <Box mt={4}>
                  <CrossPlatformLink to="me.moronline.station://consult">
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      ปรึกษาแพทย์
                    </Button>
                  </CrossPlatformLink>
                </Box>
              ) : (
                <>
                  {
                    !!partnerId ? (
                      <Box mt={4}>
                        <CrossPlatformLink to={`me.moronline://partners/consult/${partnerId}`}>
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                          >
                            ปรึกษาแพทย์
                          </Button>
                        </CrossPlatformLink>
                      </Box>
                    ) : (
                      <Box mt={4}>
                        <CrossPlatformLink to="me.moronline://consult">
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                          >
                            ปรึกษาแพทย์
                          </Button>
                        </CrossPlatformLink>
                      </Box>
                    )
                  }
                  <Box mt={1} style={{display: !verify? 'block' : 'none'}}>
                    <CrossPlatformLink to="me.moronline://drugStore">
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                      >
                        เลือกซื้อยา
                      </Button>
                    </CrossPlatformLink>
                  </Box>
                  <Box mt={1}>
                    <Link to="/assessment/menu">
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                      >
                        ทำแบบประเมินอื่น
                      </Button>
                    </Link>
                  </Box>
                </>
              )
            }
          </>
        </LoaderView>
      </Box>
    </Layout>
  );
}
