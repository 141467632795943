const URL = 'https://api.ai-service.moronline.me/api/v3';

async function post(url, body) {
  let response = await fetch(`${URL}${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Api-Key ' + process.env.GATSBY_AI_KEY,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  });
  return await response.json();
}

async function get(url) {
  let response = await fetch(`${URL}${url}`, {
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Api-Key ' + process.env.GATSBY_AI_KEY,
    },
    mode: 'cors',
  });
  return await response.json();
}

export const getQuestion = (session, symptom, gender) => {
  return post('/symptom/', {
    session,
    symptom,
    gender,
  });
};

export const getSymptoms = async () => {
  return get('/symptom/');
};

export const postAnswer = async (session, value, question) => {
  return post('/answer/', {
    session,
    value,
    question,
  });
};

export const postSummary = async session => {
  return post('/summary/', {
    session,
    model: 'neural_network',
  });
};
